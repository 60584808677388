import script from "./MapaUbicaciones.vue?vue&type=script&setup=true&lang=js"
export * from "./MapaUbicaciones.vue?vue&type=script&setup=true&lang=js"

import "./MapaUbicaciones.vue?vue&type=style&index=0&id=2eda36f5&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QTooltip,QChip,QAvatar,QCardActions,QBtn,QIcon,QCircularProgress});qInstall(script, 'directives', {ClosePopup});
